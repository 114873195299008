import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueCookie from 'vue-cookie'
// api: https://github.com/ElemeFE/element
import '@/element-ui'
// api: http://www.iconfont.cn/
import '@/icons'
import 'element-ui/lib/theme-chalk/index.css'
import '@/assets/scss/index.scss'
// api: https://github.com/axios/axios
import axios from 'axios'
import httpRequest from '@/utils/httpRequest'
import { isAuth, isAuths, unitChange, getMonthLastDay, formatTime, isAmongAuth } from '@/utils'
import { getWaybillStatus, getDeliveryStatus, getTaskStatus, getProjectStatus, getSupplyTaskStatus, getDistinguishName } from '@/utils/getStatus'
import { replaceWord, isRMB } from './utils/replaceWord'
// import { upload } from '@/utils/upload'
import OSS from 'ali-oss'
// 打印机
import Print from 'vue-print-nb'
// 复制黏贴
import VueClipboard from 'vue-clipboard2'

// 富文本编辑器
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

// dataV注册
import dataV from '@jiaminghi/data-view'

window.SITE_CONFIG = {
  timeLimit: true,
  baseUrl: 'https://mine.gongkuangyun.com/yf'
}

Vue.use(VueClipboard)
Vue.use(VueQuillEditor)
Vue.use(Print)
Vue.use(VueCookie)
Vue.use(dataV)
Vue.config.productionTip = false
Vue.directive('removeAriaHidden', { //解决 el-radio-group 的 有关标签属性-aria-hidden 报错
  bind(el, binding) {
    let ariaEls = el.querySelectorAll('.el-radio__original');
    ariaEls.forEach((item) => {
      item.removeAttribute('aria-hidden');
    });
  }
});

// 非生产环境, 适配 mockjs 模拟数据，api: https://github.com/nuysoft/Mock
// if (process.env.NODE_ENV !== 'production') {
//   require('@/mock')
// }

// 挂载全局
// 列表状态
Vue.prototype.getWaybillStatus = getWaybillStatus
Vue.prototype.getDeliveryStatus = getDeliveryStatus
Vue.prototype.getTaskStatus = getTaskStatus
Vue.prototype.getProjectStatus = getProjectStatus
Vue.prototype.getSupplyTaskStatus = getSupplyTaskStatus
// 材料类型
Vue.prototype.getDistinguishName = getDistinguishName
// 根据当前月第一天时间获取最后一天的时间戳
Vue.prototype.getMonthLastDay = getMonthLastDay
// 全局替换字段
Vue.prototype.replaceWord = replaceWord
// 全局開關
Vue.prototype.switchs = parseInt(localStorage.getItem('switch'))
// 时间戳转字符串
Vue.prototype.formatTime = formatTime
// 切换人名币符号
Vue.prototype.isRMB = isRMB
// 价格单位转换
Vue.prototype.unitChange = unitChange
// ajax 请求方法
Vue.prototype.$http = httpRequest
// 权限方法
Vue.prototype.isAuth = isAuth
Vue.prototype.isAuths = isAuths
Vue.prototype.isAmongAuth = isAmongAuth
// axios 请求
Vue.prototype.axios = axios
// 自定义方法
// Vue.prototype.upload = upload
Vue.prototype.laborProcessing = function (data) {
  if (!data) {
    return ''
  } else {
    let departmentName = ''
    data.forEach(item => {
      departmentName = departmentName + item.name + '\n'
    })
    return departmentName
  }
}
/* eslint-disable no-new */

Vue.use(dataV)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
